.DayPickerInput-Overlay {
    z-index: 9999;
}
.DayPickerInput--Range
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #e6f6fe !important;
    color: #5c879a !important;
}
@media (min-width: 768px) {
    .DayPickerInput .DayPickerInput-Overlay {
        width: 550px !important;
    }
}
.DayPickerInput--Range .DayPickerInput {
    display: block !important;
}
.DayPickerInput--Range .DayPicker-Day {
    border-radius: 0 !important;
}
.DayPickerInput--Range .DayPicker-Day--start:not(.DayPicker-Day--outside) {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
    background-color: #0288d1 !important;
    color: #ffffff !important;
}
.DayPickerInput--Range .DayPicker-Day--end:not(.DayPicker-Day--outside) {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
    background-color: #0288d1 !important;
    color: #ffffff !important;
}
