.wishlist {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.wishlist > div {
    margin-bottom: 0.5em;
}
.wishlist > div:last-of-type {
    margin-bottom: 0;
}
